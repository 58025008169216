angular.module('fingerink')
    .config(function config($stateProvider) {
        $stateProvider.state('main.team', {
            url: '/configuration/team',
            views: {
                "main": {
                    controller: 'TeamCtrl',
                    templateUrl: 'web/main/team/team.tpl.html',
                    controllerAs: 'controller'
                }
            },
            resolve: {
                users: userService => userService.getUsuarios(),
                order: (orderService) => orderService.getActualOrder(),
                totals: signatureService => signatureService.getFirmasTotales()
            },
            data: {
                pageTitle: 'Team'
            }
        });
    })

    .controller('TeamCtrl', function ($scope, session, userService, $q, orderService, users, signatureService, order, totals, newLicenseModal, changeUserModal, changeUserPasswordModal) {

        var that = this;

        var init = (users, order, totals) => {
            that.user = session.get().user;
            that.empresa = session.get().user.company;
            that.users = users;
            that.state = session.get().state;
            that.iniciales = (name, surname) => (name + (surname ? ' ' + surname : '')).split(' ').map(a => a[0]).reduce((a, b) => a + b);
            that.order = order;

            if(that.order.orderPeriods && that.order.orderPeriods[0] &&  that.order.orderPeriods[0].orderPeriodStates){
                that.serviceStatesMap = that.order.orderPeriods[0].orderPeriodStates.reduce((acc, item) => acc.set(item.user.id, item), new Map());
            }
            that.totals = _.groupBy(totals.filter(t => t.month == new Date().getMonth() + 1 && t.year == new Date().getFullYear()), e => e.user);
        };
        init(users.data, order.data, totals.data);

        var update = () => $q.all([ userService.getUsuarios(),   orderService.getActualOrder(), signatureService.getFirmasTotales()]).then(r => init.apply(null, _.map(r, i => i.data)));

        
        that.newLicense = () => newLicenseModal.openModal();

        that.changeUser = user => changeUserModal.openModal(user.id).result.then(update);
        that.changeUserPassword = user => changeUserPasswordModal.openModal(user.id).result.then(update);
        that.disableUser = user => changeUserModal.openModal(user.id);

        
        $scope.$on("sessionChanged", () => update);

    });
